import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./appreciate/appreciate.module').then((m) => m.AppreciateModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./my-feed/my-feed.module').then((m) => m.MyFeedModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./feeds/feeds.module').then((m) => m.FeedsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./rewards/rewards.module').then((m) => m.RewardsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./point-history/point-history.module').then(
        (m) => m.PointHistoryModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./reward-detail/reward-detail.module').then(
        (m) => m.RewardDetailModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./redeem/redeem.module').then((m) => m.RedeemModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./app-support/app-support.module').then(
        (m) => m.AppSupportModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./my-nominations/my-nominations.module').then(
        (m) => m.MyNominationsModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./my-nominations-profile/my-nominations-profile.module').then(
        (m) => m.MyNominationsProfileModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./special-deal/special-deal.module').then(
        (m) => m.SpecialDealModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./calendar-detail/calendar-detail.module').then(
        (m) => m.CalendarDetailModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./notification/notification.module').then(
        (m) => m.NotificationModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./my-cart/my-cart.module').then((m) => m.MyCartModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./my-badges/my-badges.module').then((m) => m.MyBadgesModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./my-core-values/my-core-values.module').then(
        (m) => m.MyCoreValuesModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./leaderboard/leaderboard.module').then(
        (m) => m.LeaderboardModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./benefit-receipts/benefit-receipts.module').then(
        (m) => m.BenefitReceiptsModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./dealer-management/dealer-management.module').then(
        (m) => m.DealerManagementModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./benefit-receipts/benefit-receipts.module').then((m) => m.BenefitReceiptsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./sales-receipts/sales-receipts.module').then((m) => m.SalesReceiptsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./quicklink/quicklink.module').then((m) => m.QuicklinkModule),
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

const feeds = environment.settings.feeds;
if (feeds.path !== 'home') {
  routes.push({
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
